.sg-tooltip {
  --tooltip-color: var(--black);

  box-shadow: $shadowSmall !important;
  background-color: var(--tooltip-color);
  padding: 8px 12px;
  border-radius: 6px;
  min-width: 56px; // 24px of arrow width + 16px padding on each side
  min-height: 36px;

  transition: opacity $durationQuick2 $easingLinear,
    transform $durationModerate2 $easingEntry;

  @media (prefers-reduced-motion) {
    transition: opacity $durationQuick2 $easingLinear;
  }

  @media (forced-colors: active) {
    border: 2px solid CanvasText;
  }

  @mixin initialState() {
    opacity: 0;

    &[data-placement^='top'] {
      transform: translateY(8px);
    }

    &[data-placement^='bottom'] {
      transform: translateY(-8px);
    }

    &[data-placement^='left'] {
      transform: translateX(8px);
    }

    &[data-placement^='right'] {
      transform: translateX(-8px);
    }

    &[data-placement] {
      @media (prefers-reduced-motion) {
        transform: none;
      }
    }
  }

  @include initialState();

  &[data-status='open'] {
    opacity: 1;

    &[data-placement^='top'],
    &[data-placement^='bottom'] {
      transform: translateY(0);
    }

    &[data-placement^='left'],
    &[data-placement^='right'] {
      transform: translateX(0);
    }
  }

  &[data-status='close'] {
    transition-duration: $durationQuick2, $durationModerate1;
    transition-timing-function: $easingLinear, $easingExit;
    transition-delay: 0.06s, 0;

    @media (prefers-reduced-motion) {
      transition-delay: 0, 0;
    }

    @include initialState();
  }

  &--small {
    padding: 2px 8px;
    min-height: 24px;
    min-width: 44px; // 12px of arrow width + 16px padding on each side
  }

  &--light {
    --tooltip-color: var(--white);
  }

  &__label {
    max-width: 50ch;
    word-break: normal;
    overflow-wrap: anywhere;
  }

  &__arrow {
    fill: var(--tooltip-color);
    filter: drop-shadow(2px 0 8px rgba($gray-70, 0.2));

    & path {
      @media (forced-colors: active) {
        stroke: CanvasText;
        stroke-width: 2px;
        stroke-dasharray: 23 28;
        stroke-dashoffset: -3;
      }
    }
  }
}
