$includeHtml: false !default;
$includeFonts: true !default;
$proximaNovaRegularWoff2Path: $sgFontsPath + 'ProximaNova-Regular.woff2';
$proximaNovaRegularWoffPath: $sgFontsPath + 'ProximaNova-Regular.woff';
$proximaNovaBoldWoff2Path: $sgFontsPath + 'ProximaNova-Bold.woff2';
$proximaNovaBoldWoffPath: $sgFontsPath + 'ProximaNova-Bold.woff';
$proximaNovaBlackWoff2Path: $sgFontsPath + 'ProximaNova-Black.woff2';
$proximaNovaBlackWoffPath: $sgFontsPath + 'ProximaNova-Black.woff';
$untitledSerifRegularWoff2Path: $sgFontsPath + 'UntitledSerif-Regular.woff2';
$untitledSerifRegularWoffPath: $sgFontsPath + 'UntitledSerif-Regular.woff';

@if ($includeHtml and $includeFonts) {
  @font-face {
    font-family: $fontFamilyProximaNova;
    src: url($proximaNovaRegularWoff2Path) format('woff2'),
      url($proximaNovaRegularWoffPath) format('woff');
    font-weight: $fontWeightNormal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: $fontFamilyProximaNova;
    src: url($proximaNovaBoldWoff2Path) format('woff2'),
      url($proximaNovaBoldWoffPath) format('woff');
    font-weight: $fontWeightBold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: $fontFamilyProximaNova;
    src: url($proximaNovaBlackWoff2Path) format('woff2'),
      url($proximaNovaBlackWoffPath) format('woff');
    font-weight: $fontWeightBlack;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: $fontFamilyUntitledSerif;
    src: url($untitledSerifRegularWoff2Path) format('woff2'),
      url($untitledSerifRegularWoffPath) format('woff');
    font-weight: $fontWeightNormal;
    font-style: normal;
    font-display: swap;
  }
}
