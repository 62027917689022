@use 'sass:math';
$buttonRoundColor: $black;
$buttonRoundBoxShadow: $shadowSmall;
$buttonHRoundHoverBoxShadow: $shadowMedium;
$buttonRoundAddSize: rhythm(2);
$buttonRoundAddColor: $white;
$buttonRoundLabelFontSize: fontSize(obscure);
$buttonRoundLabelBackground: $blue-50;

$includeHtml: false !default;

@if ($includeHtml) {
  .sg-button-solid-round {
    @include applyFocus();

    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    outline-width: 0;
    transition: transform 0.3s;

    &:active {
      transform: $buttonActiveScale;
    }

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }

    &:hover {
      .sg-button-solid-round__icon,
      .sg-button-solid-round__label {
        box-shadow: $buttonHRoundHoverBoxShadow;
      }
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $buttonRoundAddSize;
      height: $buttonRoundAddSize;
      border-radius: var(--border-radius-pill);
      line-height: $buttonRoundAddSize;
      text-align: center;
      background-color: $buttonRoundColor;
      color: $buttonRoundAddColor;
      box-shadow: $buttonRoundBoxShadow;
      transition: box-shadow 0.3s;
      flex-shrink: 0;
    }

    &__label {
      display: flex;
      font-weight: $fontWeightBold;
      font-size: $buttonRoundLabelFontSize;
      border-radius: 5px;
      line-height: rhythm(1);
      padding: 0 gutter(math.div(1, 2));
      margin-right: gutter(math.div(1, 6));
      background: $buttonRoundLabelBackground;
      color: $buttonRoundAddColor;
      box-shadow: $buttonRoundBoxShadow;
      transition: box-shadow 0.3s;
    }
  }
}
