@use 'sass:math';
$includeHtml: false !default;

@if ($includeHtml) {
  .sg-content-box {
    &__title {
      @include componentsContainer();
      min-height: rhythm(2);

      &--spaced {
        @include sgBreakpoint(medium-up) {
          padding-left: gutter(2);
        }
      }

      &--spaced-small {
        @include sgBreakpoint(medium-up) {
          padding-left: gutter(1);
        }
      }
    }

    &__header {
      @include componentsContainer();
      height: rhythm(2);

      &--spaced {
        @include sgBreakpoint(medium-up) {
          padding-left: gutter(2);
        }
      }

      &--spaced {
        @include sgBreakpoint(medium-up) {
          padding-left: gutter(1);
        }
      }
    }

    &__content {
      word-wrap: break-word;

      &--full {
        width: 100%;
        height: 100%;
      }

      &--with-centered-text {
        text-align: center;
      }
    }

    &__actions {
      @include componentsContainer();
      min-height: rhythm(2);

      &--with-elements-to-right {
        justify-content: flex-end;
      }
    }

    &--spaced {
      > .sg-content-box__content,
      > .sg-content-box__actions {
        @include sgBreakpoint(medium-up) {
          padding-left: gutter(2);
        }
      }
    }

    &--spaced-small {
      > .sg-content-box__content,
      > .sg-content-box__actions {
        @include sgBreakpoint(medium-up) {
          padding-left: gutter(1);
        }
      }
    }

    &--full {
      width: 100%;
    }

    &__title,
    &__header,
    &__actions {
      &--with-centered-elements {
        justify-content: center;
      }
    }

    &,
    &__content,
    &__title,
    &__header,
    &__actions {
      &--spaced-bottom {
        margin-bottom: rhythm(0.5);
      }

      &--spaced-bottom-small {
        margin-bottom: rhythm(math.div(1, 3));
      }

      &--spaced-bottom-xsmall {
        margin-bottom: rhythm(math.div(1, 4));
      }

      &--spaced-bottom-xxsmall {
        margin-bottom: rhythm(math.div(1, 6));
      }

      &--spaced-bottom-large {
        margin-bottom: rhythm(1);
      }

      &--spaced-bottom-xlarge {
        margin-bottom: rhythm(1.5);
      }

      &--spaced-bottom-xxlarge {
        margin-bottom: rhythm(2);
      }

      &--spaced-top {
        margin-top: rhythm(0.5);
      }

      &--spaced-top-small {
        margin-top: rhythm(math.div(1, 3));
      }

      &--spaced-top-xsmall {
        margin-top: rhythm(math.div(1, 4));
      }

      &--spaced-top-xxsmall {
        margin-top: rhythm(math.div(1, 6));
      }

      &--spaced-top-large {
        margin-top: rhythm(1);
      }

      &--spaced-top-xlarge {
        margin-top: rhythm(1.5);
      }

      &--spaced-top-xxlarge {
        margin-top: rhythm(2);
      }
    }
  }
}
