@use 'sass:math';
$mediaItemColor: $black;
$mediaItemSecondaryColor: $gray-70;

.sg-media {
  display: flex;
  width: 100%;
  padding: rhythm(math.div(1, 2));
  line-height: rhythm(1);
  min-height: rhythm(2.5);
  border-radius: var(--border-radius-xxxs);

  &--no-padding {
    padding: 0;
  }

  &--white {
    background-color: $white;
  }

  &--blue-20 {
    background-color: $blue-20;
  }

  &--transparent {
    background-color: transparent;
  }

  &--gray-20 {
    background-color: $gray-20;
  }

  &--clickable {
    cursor: pointer;

    &:hover {
      background-color: $gray-40;
    }
  }

  &--to-right {
    flex-direction: row-reverse;

    > .sg-media__aside {
      margin: 0 0 0 gutter(math.div(1, 2));
    }

    > .sg-media__wrapper {
      align-items: flex-end;
    }
  }

  &__aside {
    margin-right: gutter(math.div(1, 2));
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 0;
    position: relative;
    flex: 1 1 0;
  }

  &__content {
    @include typeVariant(medium, math.div(2, 3));
    color: $mediaItemColor;
    word-wrap: break-word;

    &--small {
      @include typeVariant(small, math.div(2, 3));
      color: $mediaItemSecondaryColor;
    }

    &--spaced-bottom {
      margin-bottom: rhythm(math.div(1, 3));
    }
  }

  &__actions {
    display: flex;
  }
}
