// private mixin for widths calculations
/* stylelint-disable-next-line scss/at-mixin-pattern */
@mixin _calculateFocusWidths() {
  --totalWidth: calc(var(--focusInnerWidth) + var(--focusWidth));
  --totalOuterWidth: calc(
    var(--focusInnerWidth) + var(--focusWidth) + var(--focusOuterWidth)
  );
}

@mixin applyFocusStyle() {
  @include _calculateFocusWidths();

  box-shadow: 0px 0px 0px var(--focusInnerWidth) var(--focusInnerColor),
    0px 0px 0px var(--totalWidth) var(--focusColor),
    0px 0px 0px var(--totalOuterWidth) var(--focusOuterColor);
  outline: none;
  animation: focusShadow1 $durationQuick2 $easingEntry,
    focusShadow2 $durationQuick2 $easingEntry calc($durationQuick2 / 2),
    focusShadow3 $durationQuick2 $easingEntry $durationQuick2;

  @media (forced-colors: active) {
    outline-color: Highlight;
    outline-width: var(--focusWidth);
    outline-offset: var(--totalOuterWidth);
    outline-style: auto;
  }
}

@keyframes focusShadow1 {
  0% {
    box-shadow: 0px 0px 0px 0px var(--focusInnerColor);
  }

  100% {
    box-shadow: 0px 0px 0px var(--focusInnerWidth) var(--focusInnerColor);
  }
}

@keyframes focusShadow2 {
  0% {
    box-shadow: 0px 0px 0px calc(0.9 * var(--focusInnerWidth))
      var(--focusInnerColor);
  }

  100% {
    box-shadow: 0px 0px 0px var(--focusInnerWidth) var(--focusInnerColor),
      0px 0px 0px var(--totalWidth) var(--focusColor);
  }
}

@keyframes focusShadow3 {
  0% {
    box-shadow: 0px 0px 0px var(--focusInnerWidth) var(--focusInnerColor),
      0px 0px 0px calc(0.95 * var(--totalWidth)) var(--focusColor);
  }

  100% {
    box-shadow: 0px 0px 0px var(--focusInnerWidth) var(--focusInnerColor),
      0px 0px 0px var(--totalWidth) var(--focusColor),
      0px 0px 0px var(--totalOuterWidth) var(--focusOuterColor);
  }
}

@mixin applyFocusTextStyle() {
  @include _calculateFocusWidths();

  outline-color: var(--focusColor);
  outline-width: var(--focusWidth);
  outline-offset: var(--totalWidth);
  outline-style: auto;
}

@mixin applyFocus() {
  transition: box-shadow $durationQuick1 $easingExit;

  &:focus-visible {
    @include applyFocusStyle();
  }

  &:focus {
    @include applyFocusStyle();
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
    animation: none;
  }
}

@mixin applyFocusText() {
  &:focus-visible {
    @include applyFocusTextStyle();
  }

  &:focus {
    @include applyFocusTextStyle();
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}
