$includeHtml: false !default;

@if ($includeHtml) {
  .sg-input {
    @include component();
    @include applyFocus();

    background: $formElementDefaultBackgroundColor;
    border: 2px solid $formElementDefaultBackgroundColor;
    border-radius: $formElementStandardBorderRadius;
    color: $formElementDefaultTextColor;
    font-size: $formElementDefaultFontSize;
    font-weight: normal;
    padding: 0 spacing(s);
    height: $formElementSizeM;
    appearance: none;
    transition: background 100ms, border 100ms;

    &:focus:not([disabled]),
    &:active:not([disabled]),
    &:focus:hover:not([disabled]),
    &:active:hover:not([disabled]) {
      background-color: $white;
      border: 2px solid $gray-40;

      &::placeholder {
        visibility: visible;
      }
    }

    &[disabled] {
      &::placeholder {
        color: $gray-50;
      }
    }

    &::placeholder {
      color: $formElementPlacholderTextColor;
      font-size: $formElementDefaultFontSize;
      font-weight: normal;
      position: relative;
      opacity: 1;
    }

    &:hover:not([disabled]) {
      background-color: mix($formElementDefaultBackgroundColor, $gray-40, 80%);
      border: 2px solid mix($formElementDefaultBackgroundColor, $gray-40, 80%);
    }

    &--white {
      background: $white;
      border: 2px solid $white;
      border-radius: $formElementStandardBorderRadius;

      &:hover:not([disabled]) {
        background-color: mix($white, $gray-10, 12%);
        border: 2px solid mix($white, $gray-10, 12%);
      }

      &.sg-input--valid {
        &:hover:not([disabled]) {
          background-color: mix($white, $gray-10, 12%);
          border: 2px solid $formElementValidColor;
        }
      }

      &.sg-input--invalid {
        &:hover:not([disabled]) {
          background-color: mix($white, $gray-10, 12%);
          border: 2px solid $formElementValidColor;
        }
      }
    }

    &--valid {
      &:not([disabled]) {
        border: 2px solid $formElementValidColor;
        color: $black;
        font-weight: normal;
      }

      &:focus:not([disabled]),
      &:active:not([disabled]),
      &:focus:hover:not([disabled]),
      &:active:hover:not([disabled]) {
        background-color: $white;
        border: 2px solid $formElementValidColor;
        box-shadow: none;
      }

      &:hover:not([disabled]) {
        background-color: mix(
          $formElementDefaultBackgroundColor,
          $gray-40,
          80%
        );
        border: 2px solid $formElementValidColor;
      }
    }

    &--invalid {
      &:not([disabled]) {
        border: 2px solid $formElementInvalidColor;
        color: $black;
        font-weight: normal;
      }

      &:focus:not([disabled]),
      &:active:not([disabled]),
      &:focus:hover:not([disabled]),
      &:active:hover:not([disabled]) {
        background-color: $white;
        border: 2px solid $formElementInvalidColor;
        box-shadow: none;
      }

      &:hover:not([disabled]) {
        background-color: mix(
          $formElementDefaultBackgroundColor,
          $gray-40,
          80%
        );
        border: 2px solid $formElementInvalidColor;
      }
    }

    &--full-width {
      width: 100%;
    }

    &--l {
      height: $formElementSizeL;
      font-size: $formElementLargeFontSize;
      border-radius: $formElementLargeBorderRadius;
      padding: 0 gutter(1);

      &::placeholder {
        font-size: fontSize(standout);
      }
    }

    &--s {
      height: componentSize(s);
      font-size: fontSize(small);
      border-radius: $formElementStandardBorderRadius;
      padding: 0 calc(spacing(xs) + spacing(xxs));

      &::placeholder {
        font-size: fontSize(small);
      }
    }

    &--with-icon {
      padding-right: 40px;
    }

    &__wrapper {
      display: inline-block;
      max-width: min-content;

      &--full-width {
        width: 100%;
        max-width: 100%;
      }

      &--disabled {
        opacity: 0.45;
      }
    }
  }
}
