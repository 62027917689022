$includeHtml: false !default;

@if ($includeHtml) {
  .sg-search {
    @include component();
    overflow: visible;

    &__icon {
      &--s.sg-button--icon-only {
        height: componentSize(xs);
        width: componentSize(xs);
      }

      &--transparent.sg-button--icon-only {
        width: 28px;

        &.sg-search__icon--l {
          width: 36px;
        }

        & .sg-button__hover-overlay {
          display: none;
        }
      }
    }

    &-icon-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;

      &--s {
        height: componentSize(xs);
      }
    }

    &__input {
      width: 100%;
    }

    &--full-width {
      width: 100%;
    }

    &--l {
      .sg-search-icon-wrapper {
        right: spacing(xs);
      }
    }
  }
  // Normalize adds -webkit-appearance: textfield and that changes styling for iOS
  input[type='search'].sg-search__input {
    -webkit-appearance: none;
  }
}
