.sg-radio-group {
  display: inline-flex;
  flex-direction: column;

  &__items {
    display: flex;

    &--direction-column {
      flex-direction: column;
    }

    &--direction-row {
      flex-direction: row;
    }
  }

  .sg-error-message {
    margin-top: 4px;
  }
}
