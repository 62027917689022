@use 'sass:math';
.sg-actions-list {
  line-height: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;

  > .sg-actions-list__hole {
    max-width: 100%;
    margin: 0 gutter(math.div(1, 2)) 0 0;

    &--spaced-small {
      margin: 0 gutter(math.div(1, 3)) 0 0;
    }

    &--spaced-xsmall {
      margin: 0 gutter(math.div(1, 4)) 0 0;
    }

    &--spaced-large {
      margin: 0 gutter(1) 0 0;
    }

    &--container {
      position: relative;
    }

    &--no-spacing {
      margin: 0;
    }

    &--to-right {
      margin-left: auto;
    }

    &--space-bellow {
      margin-bottom: gutter(0.5);
    }

    &--no-shrink {
      flex-shrink: 0;
    }

    &--grow {
      flex-grow: 1;
    }

    &--to-end {
      align-self: flex-end;
    }

    &--stretch {
      align-self: stretch;
    }

    &--equal-width {
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;
    }

    &--hide-overflow {
      overflow: hidden;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &--align-baseline {
    align-items: baseline;
  }

  &--stretch {
    align-items: stretch;
  }

  &--to-top {
    align-items: flex-start;
  }

  &--centered {
    justify-content: center;
  }

  &--to-right {
    justify-content: flex-end;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--space-around {
    justify-content: space-around;
  }

  &--space-evenly {
    justify-content: space-evenly;
  }

  &--no-wrap {
    flex-wrap: nowrap;
  }
}
