/* DO NOT EDIT THIS FILE - it is generated during build process;
* all colors are defined in /src/components/colors/colors.js */
:root {
  --black: #{$black};
  --white: #{$white};
  --blue-70: #{$blue-70};
  --blue-60: #{$blue-60};
  --blue-50: #{$blue-50};
  --blue-40: #{$blue-40};
  --blue-30: #{$blue-30};
  --blue-20: #{$blue-20};
  --blue-10: #{$blue-10};
  --green-70: #{$green-70};
  --green-60: #{$green-60};
  --green-50: #{$green-50};
  --green-40: #{$green-40};
  --green-30: #{$green-30};
  --green-20: #{$green-20};
  --green-10: #{$green-10};
  --indigo-70: #{$indigo-70};
  --indigo-60: #{$indigo-60};
  --indigo-50: #{$indigo-50};
  --indigo-40: #{$indigo-40};
  --indigo-30: #{$indigo-30};
  --indigo-20: #{$indigo-20};
  --indigo-10: #{$indigo-10};
  --red-70: #{$red-70};
  --red-60: #{$red-60};
  --red-50: #{$red-50};
  --red-40: #{$red-40};
  --red-30: #{$red-30};
  --red-20: #{$red-20};
  --red-10: #{$red-10};
  --yellow-70: #{$yellow-70};
  --yellow-60: #{$yellow-60};
  --yellow-50: #{$yellow-50};
  --yellow-40: #{$yellow-40};
  --yellow-30: #{$yellow-30};
  --yellow-20: #{$yellow-20};
  --yellow-10: #{$yellow-10};
  --gray-70: #{$gray-70};
  --gray-60: #{$gray-60};
  --gray-50: #{$gray-50};
  --gray-40: #{$gray-40};
  --gray-30: #{$gray-30};
  --gray-20: #{$gray-20};
  --gray-10: #{$gray-10};
  --text-black: #{$text-black};
  --text-white: #{$text-white};
  --text-blue-60: #{$text-blue-60};
  --text-blue-40: #{$text-blue-40};
  --text-green-60: #{$text-green-60};
  --text-green-40: #{$text-green-40};
  --text-indigo-60: #{$text-indigo-60};
  --text-indigo-40: #{$text-indigo-40};
  --text-red-60: #{$text-red-60};
  --text-red-40: #{$text-red-40};
  --text-yellow-60: #{$text-yellow-60};
  --text-yellow-40: #{$text-yellow-40};
  --text-gray-70: #{$text-gray-70};
  --text-gray-60: #{$text-gray-60};
  --text-gray-50: #{$text-gray-50};
  --text-gray-40: #{$text-gray-40};
  --icon-black: #{$icon-black};
  --icon-white: #{$icon-white};
  --icon-blue-60: #{$icon-blue-60};
  --icon-blue-50: #{$icon-blue-50};
  --icon-green-60: #{$icon-green-60};
  --icon-green-50: #{$icon-green-50};
  --icon-indigo-60: #{$icon-indigo-60};
  --icon-indigo-50: #{$icon-indigo-50};
  --icon-red-60: #{$icon-red-60};
  --icon-red-50: #{$icon-red-50};
  --icon-yellow-60: #{$icon-yellow-60};
  --icon-yellow-50: #{$icon-yellow-50};
  --icon-gray-70: #{$icon-gray-70};
  --icon-gray-60: #{$icon-gray-60};
  --icon-gray-50: #{$icon-gray-50};
  --icon-gray-40: #{$icon-gray-40};
}
