$includeHtml: false !default;

@if ($includeHtml) {
  :root {
    --border-radius-none: 0;
    --border-radius-xxxs: 4px;
    --border-radius-xxs: 6px;
    --border-radius-xs: 8px;
    --border-radius-s: 12px;
    --border-radius-m: 16px;
    --border-radius-l: 24px;
    --border-radius-xl: 32px;
    --border-radius-xxl: 40px;
    --border-radius-pill: 999px;
  }

  html {
    font-size: $baseFont;
  }

  * {
    box-sizing: border-box;
  }

  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    --focusColor: #{$indigo-40};
    --focusInnerColor: #{$white};
    --focusOuterColor: rgba(109, 131, 243, 0.3);

    --focusWidth: 2px;
    --focusInnerWidth: 2px;
    --focusOuterWidth: 2px;

    --pressTransitionDuration: #{$durationModerate2};
    --pressTransitionEasing: #{$easingRegular};
    --pressScale: 0.96;
    @media (prefers-reduced-motion) {
      --pressScale: 1;
    }

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: fontSize(default);
    font-family: $fontFamilyPrimary;
    line-height: rhythm(1);
  }

  *:not(a) {
    @include applyFocus();
  }

  a {
    @include applyFocusText();
  }
}
