@mixin textColor() {
  &--text-white {
    color: $text-white;
  }

  &--text-black {
    color: $text-black;
  }

  &--text-gray-70 {
    color: $text-gray-70;
  }

  &--text-gray-60 {
    color: $text-gray-60;
  }

  &--text-gray-50 {
    color: $text-gray-50;
  }

  &--text-gray-40 {
    color: $text-gray-40;
  }

  &--text-blue-60 {
    color: $text-blue-60;
  }

  &--text-blue-40 {
    color: $text-blue-40;
  }

  &--text-green-60 {
    color: $text-green-60;
  }

  &--text-green-40 {
    color: $text-green-40;
  }

  &--text-indigo-60 {
    color: $text-indigo-60;
  }

  &--text-indigo-40 {
    color: $text-indigo-40;
  }

  &--text-red-60 {
    color: $text-red-60;
  }

  &--text-red-40 {
    color: $text-red-40;
  }

  &--text-yellow-60 {
    color: $text-yellow-60;
  }

  &--text-yellow-40 {
    color: $text-yellow-40;
  }
}
