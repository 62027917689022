$horizontalSeparatorHeight: 0.0625rem;

$includeHtml: false !default;

@if ($includeHtml) {
  .sg-horizontal-separator {
    @include component();
    min-height: 0;
    display: block;
    height: $horizontalSeparatorHeight;
    border-bottom-style: solid;
    border-bottom-width: $horizontalSeparatorHeight;
    width: 100%;

    &--spaced {
      margin: (rhythm(0.5) - $horizontalSeparatorHeight) 0 rhythm(0.5);

      @include sgBreakpoint(medium-up) {
        margin: (rhythm(1) - $horizontalSeparatorHeight) 0 rhythm(1);
      }
    }

    &--short-spaced {
      margin: (rhythm(0.5) - $horizontalSeparatorHeight) 0 rhythm(0.5);
    }

    &--white {
      border-color: $white;
    }

    &--gray-50 {
      border-color: $gray-50;
    }

    &--gray-40 {
      border-color: $gray-40;
    }
  }
}
