$textareaStandardBorderRadius: 20px;
$textareaStandardHeight: 96px;

$includeHtml: false !default;

@if ($includeHtml) {
  .sg-textarea {
    @include applyFocus();

    background: $formElementDefaultBackgroundColor;
    border: 2px solid $formElementDefaultBackgroundColor;
    border-radius: $textareaStandardBorderRadius;
    color: $formElementDefaultTextColor;
    display: block;
    font-size: $formElementDefaultFontSize;
    line-height: $formElementDefaultLineHeight;
    padding: 6px spacing(s);
    resize: none;
    height: $textareaStandardHeight;
    overflow-y: auto;
    appearance: none;
    position: relative;
    transition: background 100ms, border 100ms;

    &::placeholder {
      color: $formElementPlacholderTextColor;
      font-size: $formElementDefaultFontSize;
      position: relative;
    }

    &:focus,
    &:active,
    &:focus:hover,
    &:active:hover {
      background-color: $white;
      border: 2px solid $formElementActiveBorderColor;

      &::placeholder {
        visibility: visible;
      }
    }

    &:disabled {
      background-color: $formElementDisabledBackgroundColor;

      &::placeholder {
        color: $formElementDisabledPlacholderTextColor;
      }
    }

    &:hover {
      background-color: mix($formElementDefaultBackgroundColor, $gray-40, 80%);
      border: 2px solid mix($formElementDefaultBackgroundColor, $gray-40, 80%);
    }

    &--white {
      background: $white;
      border: 2px solid $white;

      &.sg-textarea--valid {
        &:hover {
          background-color: mix($white, $gray-10, 12%);
          border: 2px solid $formElementValidColor;
        }
      }

      &.sg-textarea--invalid {
        &:hover {
          background-color: mix($white, $gray-10, 12%);
          border: 2px solid $formElementInvalidColor;
        }
      }

      &:hover {
        background-color: mix($white, $gray-10, 12%);
        border: 2px solid mix($white, $gray-10, 12%);
      }
    }

    &--full-width {
      width: 100%;
    }

    &--valid {
      border: 2px solid $formElementValidColor;

      &:hover {
        border: 2px solid $formElementValidColor;
      }
    }

    &--invalid {
      border: 2px solid $formElementInvalidColor;

      &:hover {
        border: 2px solid $formElementInvalidColor;
      }
    }

    &--simple {
      border: none;
      border-radius: var(--border-radius-none);

      &:focus {
        box-shadow: none;
        border: none;
      }
    }

    &--no-padding {
      padding: 0;
    }

    &--short {
      height: 40px;
      line-height: 24px;
    }

    &--tall {
      height: 144px;
    }

    &--xtall {
      height: 168px;
    }

    &--auto-height {
      line-height: 24px;
      height: auto;
    }

    &__wrapper {
      &--full-width {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
