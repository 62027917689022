$boxBorderSize: 2px;
$boxPaddings: (none xxs xs s m l xl);

$includeHtml: false !default;

@if ($includeHtml) {
  .sg-box {
    width: 100%;

    // Background colors
    &--transparent {
      background-color: transparent;
    }

    &--white {
      background: $white;
    }

    &--gray-40 {
      background: $gray-40;
    }

    &--gray-20 {
      background-color: $gray-20;
    }

    &--gray-10 {
      background-color: $gray-10;
    }

    &--blue-40 {
      background-color: $blue-40;
    }

    &--blue-30 {
      background-color: $blue-30;
    }

    &--blue-20 {
      background-color: $blue-20;
    }

    &--green-40 {
      background-color: $green-40;
    }

    &--green-30 {
      background-color: $green-30;
    }

    &--green-20 {
      background-color: $green-20;
    }

    &--green-10 {
      background-color: $green-10;
    }

    &--indigo-40 {
      background-color: $indigo-40;
    }

    &--indigo-20 {
      background-color: $indigo-20;
    }

    &--indigo-10 {
      background-color: $indigo-10;
    }

    &--red-40 {
      background-color: $red-40;
    }

    &--red-30 {
      background-color: $red-30;
    }

    &--red-20 {
      background-color: $red-20;
    }

    &--yellow-40 {
      background-color: $yellow-40;
    }

    &--yellow-20 {
      background-color: $yellow-20;
    }
  }

  @each $breakpoint, $variant in $responsiveVariants {
    @include sgResponsive($breakpoint) {
      @include makeResponsive($variant, 'sg-box--shadow') {
        box-shadow: $shadowMedium;
      }

      @include makeResponsive($variant, 'sg-box--no-shadow') {
        box-shadow: none;
      }

      @include makeResponsive($variant, 'sg-box--no-border-radius') {
        border-radius: var(--border-radius-none);
      }

      @include makeResponsive($variant, 'sg-box--border-radius') {
        border-radius: var(--border-radius-xs);
      }

      @include makeResponsive($variant, 'sg-box--border') {
        border: $boxBorderSize solid $gray-20;
      }

      @include makeResponsive($variant, 'sg-box--no-border') {
        border: none;
      }

      @each $boxPadding in $boxPaddings {
        @include makeResponsive($variant, 'sg-box--padding-#{$boxPadding}') {
          @if $boxPadding == none {
            padding: 0;
          } @else {
            padding: spacing($boxPadding);
          }
        }

        @include makeResponsive(
          $variant,
          'sg-box--padding-#{$boxPadding}-border'
        ) {
          @if $boxPadding == none {
            padding: 0;
          } @else {
            padding: (spacing($boxPadding) - $boxBorderSize);
          }
        }
      }
    }
  }

  .sg-box {
    &--border-color-transparent {
      border-color: transparent;
    }

    &--border-color-white {
      border-color: $white;
    }

    &--border-color-gray-40 {
      border-color: $gray-40;
    }

    &--border-color-gray-20 {
      border-color: $gray-20;
    }

    &--border-color-gray-10 {
      border-color: $gray-10;
    }

    &--border-color-blue-40 {
      border-color: $blue-40;
    }

    &--border-color-blue-30 {
      border-color: $blue-30;
    }

    &--border-color-blue-20 {
      border-color: $blue-20;
    }

    &--border-color-green-40 {
      border-color: $green-40;
    }

    &--border-color-green-30 {
      border-color: $green-30;
    }

    &--border-color-green-20 {
      border-color: $green-20;
    }

    &--border-color-green-10 {
      border-color: $green-10;
    }

    &--border-color-indigo-40 {
      border-color: $indigo-40;
    }

    &--border-color-indigo-20 {
      border-color: $indigo-20;
    }

    &--border-color-indigo-10 {
      border-color: $indigo-10;
    }

    &--border-color-red-40 {
      border-color: $red-40;
    }

    &--border-color-red-30 {
      border-color: $red-30;
    }

    &--border-color-red-20 {
      border-color: $red-20;
    }

    &--border-color-yellow-40 {
      border-color: $yellow-40;
    }

    &--border-color-yellow-20 {
      border-color: $yellow-20;
    }
  }
}
