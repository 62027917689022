@import '../icons/icons-mixin';

$iconSizes: 104, 102, 80, 78, 56, 54, 40, 32, 30, 26, 24, 22, 20, 18, 16, 14, 10;

.sg-mobile-icon {
  @include iconColor();
  height: 24px;
  width: 24px;

  @each $size in $iconSizes {
    &--x#{$size} {
      max-width: #{$size}px;
      max-height: #{$size}px;
      height: #{$size}px;
      width: #{$size}px;
    }
  }
}
