.sg-transition {
  height: 100%;

  &--inline {
    display: inline-block;
  }

  &--outlines {
    outline: 1px solid $indigo-50;
  }
}
