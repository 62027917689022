@import './icons-mixin';

$includeHtml: false !default;

$iconSizes: 16, 24, 32, 40, 56, 80, 104;

@if ($includeHtml) {
  .sg-icon {
    @include iconColor();

    max-width: 24px;
    max-height: 24px;
    height: 24px;
    width: 24px;
    flex-shrink: 0;

    @each $size in $iconSizes {
      &--x#{$size} {
        max-width: #{$size}px;
        max-height: #{$size}px;
        height: #{$size}px;
        width: #{$size}px;
      }
    }

    &__svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
