@use 'sass:math';
.sg-flash {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__message {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue-30;
    min-height: rhythm(1);
    width: 100%;
    padding: rhythm(math.div(1, 4));

    &--error {
      background-color: $red-30;
    }

    &--success {
      background-color: $green-30;
    }

    &--info {
      background-color: $yellow-30;
    }
  }
}
